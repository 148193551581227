import { Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlasticCodes } from '../../enums';
import { useCallback } from 'react';

//================================================================

export default function ItemDetails(props){

  const createOn = props.item.created ? new Date(props.item.created * 1000).toLocaleString() : "unavailable";
  const modifiedOn = props.item.modified ? new Date(props.item.modified * 1000).toLocaleString() : "unavailable";
  const name = props.item.name ? props.item.name : "unnamed";
  const itemDescription = props.item.item_description ? props.item.item_description : "Item has no description.";
  const envDescription = props.item.env_description ? props.item.env_description : "Environment has no description.";
  const tags = props.item.tags ? props.item.tags.join(", ") : "Item has no tags";
  const plasticCode = props.item.plastic_code;
  const itemState  = props.item.item_state ? props.item.item_state : "unset";
  const geocoordinates = props.item.geocoordinates ? props.item.geocoordinates : null;

  //----------------------------------------------------------------

  const displayPlasticCode = useCallback(() => {
    const key = Object.keys(PlasticCodes).find(key => PlasticCodes[key]['value'] === plasticCode);
    if (key){
      return PlasticCodes[key]['name'];
    } else {
      return `BAD VALUE ${props.item.plastic_code}`
    }
  });

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="text-truncate">
          Name: {name}
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          ID: {props.item._id}
        </Col>
      </Row>*/}
      <Row>
        <Col>
          Item Description: {itemDescription}
        </Col>
      </Row>
      <Row>
        <Col>
          Environment Description: {envDescription}
        </Col>
      </Row>
      <Row>
        <Col>
          Geocoordinates:{' '}
          {geocoordinates ? <>
            <Link
              to={`https://www.openstreetmap.org/?mlat=${geocoordinates.lat}&mlon=${geocoordinates.lon}&zoom=${process.env.REACT_APP_OSM_ZOOM}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              View on OpenStreetMap
            </Link>
            {Object.entries(geocoordinates).map(([key,value]) =>
              <li key={`geocoordinates-${key}-${value}`}>
                {key}: {value}
              </li>
            )}
          </> : "Not Captured"}
        </Col>
      </Row>
      <Row>
        <Col>
          Plastic Code: {displayPlasticCode()}
        </Col>
      </Row>
      <Row>
        <Col>
          Status: {itemState}
        </Col>
      </Row>
      <Row>
        <Col>
          Tags: {tags}
        </Col>
      </Row>
      <Row>
        <Col>
          Added On: {createOn}
        </Col>
      </Row>
      {/*<Row>
        <Col>
          Last Updated: {modifiedOn}
        </Col>
      </Row>*/}
    </div>
  );
}