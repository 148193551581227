import { Col, ToggleButton } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function TagButton({tag, disabled, onSelect, onDeselect}){
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    checked ? onSelect(tag) : onDeselect(tag);
  }, [checked]);

  return(
    <Col className="col-auto">
      <ToggleButton
        id={`${tag}-check`}
        type="checkbox"
        variant="outline-secondary"
        checked={checked}
        onChange={(e) => setChecked(e.currentTarget.checked)}
        disabled={disabled}
      >
        {tag}
      </ToggleButton>
    </Col>
  );
}