import { useParams, Link } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';

import ModalShell from '../common/modal-shell';
import RowShell from '../common/row-shell';
import { Toaster, createToast } from '../common/toasts';
import PreviousNext from '../common/previous-next';
import BasicSpinner from '../common/basic-spinner';

import ItemDetails from './item-details';
import ImageCreateForm from '../image/image-create-form';
import ItemUpdateForm from './item-update-form';

//================================================================

export default function ItemPage(){

  const params = useParams();

  const [foray, setForay] = useState();
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [createModalShow, setCreateModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [images, setImages] = useState(null);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const fetchForay = useCallback(async () => {
    setForay(await fetch(`/api/foray/${params.forayId}`)
      .then((response) => {
        if (response.ok) return response.json();
        setToasts([...toasts, createToast(
          "Failed to fetch foray",
          `${response.status} - ${response.json()}`
        )]);
        return null;
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `Failed to fetch foray ${params.forayId}: ${error.message}`
        )]);
      })
    );
  }, [params.forayId]);

  useEffect(() => {
    fetchForay();
  }, []);

  //----------------------------------------------------------------

  const fetchItems = useCallback(async () => {
    if (!foray) return;

    await Promise.all(
      foray.items.map((itemId) => {
        return fetch(`/api/item/${itemId}`)
        .then((response) => {
          if(response.ok) return response.json();
          setToasts([...toasts, createToast(
            `Failed to fetch item ${itemId}`,
            `${response.status} - ${response.json()}`
          )]);
        })
      })
    )
    .then((resolved) => {
      return resolved.reduce((result, element) => {
        result.push(element);
        return result;
      }, []);
    })
    .then((reduced) => {
      setItems(reduced);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching items for foray ${foray._id}: ${error.message}`
      )]);
    });
  });

  useEffect(() => {
    fetchItems(foray);
  }, [foray]);

  //----------------------------------------------------------------

  const setCurrentItem = useCallback(async (itemId) => {
    const index = await items.findIndex((element) => element._id === itemId);
    if (index >= 0){
      items[index].index = index;
      setItem(items[index]);
    }
  }, [items]);

  useEffect(() => {
    setCurrentItem(params.itemId);
  }, [items]);

  //----------------------------------------------------------------

  const addImageToItem = useCallback(async (image) => {
    fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'add_images':[image._id]}),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      if(response.ok){
        setItem( await response.json() );
        setCreateModalShow(false);
      } else {
        setToasts([...toasts, createToast(
          "Failed to add image to item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While adding images to item ${params.itemId}: ${error.message}`
      )]);
    });
  });

  //----------------------------------------------------------------

  const removeImageFromItem = useCallback(async (imageId) => {

    fetch(`/api/item/${params.itemId}`, {
      method: 'PUT',
      body: JSON.stringify({'remove_images' : [imageId]}),
      headers: {"Content-Type": "application/json"}
    })
    .then((response) => {
      if(response.ok){
        setImages(
          images.filter(image => image._id !== imageId)
        );
      } else {
        setToasts([...toasts, createToast(
          "Failed to remove image from item",
          `${response.status}: ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While removing images from item ${params.itemId}: ${error.message}`
      )]);
    });
  }, [images]);

  //----------------------------------------------------------------

  const fetchImages = useCallback(async (item) => {
    if (!item) return;

    await Promise.all(
      item.images.map((imageId) => {
        return fetch(`/api/image/${imageId}`)
        .then((response) => {
          if(response.ok){
            const result = response.json();
            return result;
          } else if (response.status === 404) {
            return {'removeImageId': imageId}
          }
        })
        .catch((error) => {
          setToasts([...toasts, createToast(
            "Error",
            `While fetching images from item ${item._id}: ${error.message}`
          )]);
        })
      })
    )
    .then((resolved) => {
      return resolved.reduce((result, element) => {
        if('removeImageId' in element){
          removeImageFromItem(element.removeImageId);
        } else {
          result.push(element);
        }
        return result;
      }, []);
    })
    .then((reduced) => {
      setImages(reduced);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While fetching images for item ${item._id}: ${error.message}`
      )]);
    });

  });

  useEffect(() => {
    setImages(null);
    fetchImages(item);
  },[item]);

  //----------------------------------------------------------------

  return(
    <>
      <PreviousNext
        objects={items}
        currentObject={item}
        onClick={setCurrentItem}
        basePath={`/foray/${params.forayId}/item`}
      />

      <Row className="mb-2">
        <Col className="h4">Item:</Col>
      </Row>

      {item ?
        <ItemDetails item={item}/>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <Row className="mb-2 align-items-center">
        <Col className="text-start">
          <Link to={`/foray/${params.forayId}`}>
            <Button className="text-nowrap">
              Back to Parent Foray
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => setUpdateModalShow(true)}
            disabled={!item}
          >
            Edit
          </Button>
        </Col>
      </Row>

      <hr></hr>

      {images !== null ?
        <>
          <Row className="mb-3 align-items-center">
            <Col className="h4 my-0 text-start">Images:</Col>
            <Col className="text-end">
              <Button
                className="text-nowrap"
                onClick={() => setCreateModalShow(true)}
              >
                Add New Image
              </Button>
            </Col>
          </Row>

          {images.map(image => <RowShell
            key={`image-link-${image._id}`}
            id={image._id}
            linkTo={`./image/${image._id}`}
            linkText={image.name}
            onDelete={removeImageFromItem}
          />)}
        </>
        :
        <Row className="mb-3">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ModalShell
        title="Add New Image"
        show={createModalShow}
        onHide={() => setCreateModalShow(false)}
        hideFooter={true}
      >
        <ImageCreateForm
          onCancel={() => setCreateModalShow(false)}
          onCreate={addImageToItem}
        />
      </ModalShell>

      <ModalShell
        title="Update Item"
        show={updateModalShow}
        onHide={() => setUpdateModalShow(false)}
        hideFooter={true}
      >
        <ItemUpdateForm
          item={item}
          onCancel={() => setUpdateModalShow(false)}
          onUpdate={fetchItems}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}
