import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { ImageStates, ItemTypes } from '../../enums';

import BasicSpinner from '../common/basic-spinner';
import UpdateFormTags from '../common/update-form-tags';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ImageUpdateForm({image, onUpdate, onCancel}){


  const [name, setName] = useState(image.name);
  const [description, setDescription] = useState(image.description);
  const [working, setWorking] = useState(false);
  const [imageState, setImageState] = useState(image.image_state);
  const [itemType, setItemType] = useState(image.item_type);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const sendUpdateRequest = useCallback(async (data, imageId) => {
    if (Object.keys(data).length <= 0) return null;

    setWorking(true);
    const updatedImage = await fetch(`/api/image/${imageId}`, {
      method : 'PUT',
      body : JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok){
        onUpdate(result);
        return result;
      }
      setToasts([...toasts, createToast(
        "Failed to update image",
        `${response.status} - ${response.json()}`
      )]);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to update image ${imageId}: ${error.message}`
      )]);
    });
    setWorking(false);
    return updatedImage;
  }, [onUpdate]);

  const submitHandler = useCallback(async () => {
    const data = {};

    if (name !== image.name){
      data['name']=name;
    }

    if (description !== image.description){
      data['description'] = description;
    }

    if(itemType !== image.item_type){
     data['item_type'] = itemType;
    }

    if(imageState !== image.image_state){
     data['image_state'] = imageState;
    }

    await sendUpdateRequest(data, image._id);
    onCancel();
  }, [name, description, itemType, imageState, image, onCancel]);

  //----------------------------------------------------------------

  return(
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Image a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Image Description</Form.Label>
        <Form.Control
          as="textarea"
          value={description}
          placeholder="Enter description"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of the image.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item Type</Form.Label>
        <Form.Select
          onChange={(event) => setItemType(event.target.value)}
          disabled={working}
          defaultValue={itemType}
        >
          {Object.keys(ItemTypes).map(key =>
            <option
              key={`itemType-option-${key}`}
              value={ItemTypes[key]}
            >
              {ItemTypes[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Image State</Form.Label>
        <Form.Select
          onChange={(event) => setImageState(event.target.value)}
          disabled={working}
          defaultValue={imageState}
        >
          {Object.keys(ImageStates).map(key =>
            <option
              key={`imageState-option-${key}`}
              value={ImageStates[key]}
            >
              {ImageStates[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <UpdateFormTags
        object={image}
        onSubmit={sendUpdateRequest}
        disabled={working}
      />

      <Row className="align-images-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={submitHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Saving Changes..." />
              :
              "Save Changes"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
