import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from "../../utils/auth-provider";

import ModalShell from '../common/modal-shell';
import RowShell from '../common/row-shell';
import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

import ForaysInfo from './forays-info';
import ForayCreateForm from './foray-create-form';

//================================================================

export default function ForaysPage(){

  const [forays, setForays] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const redirect = useNavigate();
  const auth = useAuth();
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const deleteForay = useCallback(async (forayId) => {
    await fetch(`/api/foray/${forayId}`, {method: 'DELETE'})
    .then((response) => {
      if(response.status === 204 || response.status === 404){
        setForays(
          forays.filter(foray => foray._id !== forayId)
        );
      } else {
        setToasts([...toasts, createToast(
          "Failed to delete foray",
          `${response.status} - ${response.json()}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While deleting foray ${forayId}: ${error.message}`
      )]);
    });
  }, [forays]);

  const addForay = useCallback((foray) => {
    setForays([...forays, foray]);
    setModalShow(false);
    redirect(`../foray/${foray._id}`);
  }, [forays]);

  const fetchForays = useCallback(async () => {
    setForays(
      await fetch("/api/foray/")
      .then((response) => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        setToasts([...toasts, createToast(
          "Error",
          `While fetching forays: ${error.message}`
        )]);
      })
    );
  });

  useEffect(()=>{
    fetchForays();
  }, []);

  //----------------------------------------------------------------

  return(
    <>
      <ForaysInfo />

      <hr></hr>

      <Row className="mb-3 align-items-center">
        <Col className="h4 my-0">Forays:</Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            onClick={() => setModalShow(true)}
          >
            Start New Foray
          </Button>
        </Col>
      </Row>

      {forays !== null ?
        <>
          {forays.map(foray => <RowShell
            key={`foray-row-${foray._id}`}
            id={foray._id}
            linkTo={`../foray/${foray._id}`}
            linkText={foray.title}
            onDelete={deleteForay}
          />)}
        </>
        :
        <Row className="mb-4">
          <Col className="text-center">
            <BasicSpinner text={'Loading...'} />
          </Col>
        </Row>
      }

      <ModalShell
        title="Start New Foray"
        show={modalShow}
        onHide={() => setModalShow(false)}
        hideFooter={true}
      >
        <ForayCreateForm
          onCancel={() => setModalShow(false)}
          onCreate={addForay}
        />
      </ModalShell>

      <Toaster toasts={toasts} />
    </>
  );
}