import { Form, Row, Col, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { PlasticCodes, ItemStates } from '../../enums';

import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';
import { formatTagsJSON } from '../../utils/utils';

//================================================================

export default function ItemCreateForm({onCreate, onCancel}) {
  const namePlaceholder = `New Item: ${new Date().toLocaleString()}`;
  const [name, setName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [envDescription, setEnvDescription] = useState("");
  const [tags, setTagsString] = useState("");
  const [working, setWorking] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(! ("geolocation" in navigator));
  const [geocoordinates, setGeocoordinates] = useState();
  const [plasticCode, setPlasticCode] = useState(-1);
  const [itemState, setItemState] = useState('unset');
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const updateGeocoordinates = useCallback(async () => {
    setDisableUpdate(true);
    await new Promise(async (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    })
    .then(({coords}) => {
      setGeocoordinates({
        'lat' : coords.latitude,
        'lon' : coords.longitude,
        'accuracy' : coords.accuracy
      });
    });
    setDisableUpdate(false);
  });

  const createItemHandler = useCallback(async () => {
    setWorking(true);

    const data = {
      'name': name ? name : namePlaceholder,
      'item_description': itemDescription,
      'env_description': envDescription,
      'geocoordinates': geocoordinates,
      'plastic_code': plasticCode,
      'item_state': itemState,
      'tags': formatTagsJSON(tags)
    }

    await fetch('/api/item/', {
      method : 'POST',
      body : JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onCreate(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to create new item",
          `${response.status} - ${JSON.stringify(result, null, "\t")}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new item: ${error.message}`
      )]);
    });

    setWorking(false);
  }, [name, itemDescription, envDescription, geocoordinates, plasticCode, itemState, tags, onCreate]);

  //----------------------------------------------------------------

  return (
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={name}
          placeholder={namePlaceholder}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Item a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter descrition"
          onChange={(event) => setItemDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of the item.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Environment Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter descrition"
          onChange={(event) => setEnvDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of where the item was found.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Row className="align-items-center mb-2">
          <Col className="text-start">
            Geocoordinates
          </Col>
          <Col className="text-end">
            <Button
              className="btn-sm"
              onClick={updateGeocoordinates}
              disabled={disableUpdate}
            >
              {disableUpdate ?
                <BasicSpinner text={"Updating..."} />
                :
                "Update"
              }
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              type="float"
              value={geocoordinates ? geocoordinates.lat : ""}
              readOnly
            />
            <Form.Text>Latitude:</Form.Text>
          </Col>
          <Col>
            <Form.Control
              type="float"
              value={geocoordinates ? geocoordinates.lon : "" }
              readOnly
            />
            <Form.Text>Longitute:</Form.Text>
          </Col>
          <Col>
            <Form.Control
              type="float"
              value={geocoordinates ? geocoordinates.accuracy : ""}
              readOnly
            />
            <Form.Text>Accuracy:</Form.Text>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Plastics Code</Form.Label>
        <Form.Select
          onChange={(event) => setPlasticCode(parseInt(event.target.value))}
          disabled={working}
          defaultValue={plasticCode}
        >
          {Object.keys(PlasticCodes).map(key =>
            <option
              key={`plasticCode-option-${key}`}
              value={PlasticCodes[key]['value']}
            >
              {PlasticCodes[key]['name']}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item State</Form.Label>
        <Form.Select
          onChange={(event) => setItemState(event.target.value)}
          disabled={working}
          defaultValue={itemState}
        >
          {Object.keys(ItemStates).map(key =>
            <option
              key={`itemState-option-${key}`}
              value={ItemStates[key]}
            >
              {ItemStates[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Tags</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter tags"
          onChange={(event) => setTagsString(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Single-word tags separated by spaces, please.
        </Form.Text>
      </Form.Group>

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={createItemHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Adding New Item..." />
              :
              "Add New Item"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
