import { Image, Col, Row} from 'react-bootstrap';
import { useState } from 'react';
import BasicSpinner from '../common/basic-spinner';

//================================================================

export default function ImageDetails(props){

  const [loading, setLoading] = useState(true);
  const createOn = props.image.created ? new Date(props.image.created * 1000).toLocaleString() : "unavailable";
  const modifiedOn = props.image.modified ? new Date(props.image.modified * 1000).toLocaleString() : "unavailable";
  const name = props.image.name ? props.image.name : "unnamed";
  const filename = props.image.filename ? props.image.filename : "unset";
  const description = props.image.description ? props.image.description : "Image has no description.";
  const slug = props.image.slug ? props.image.slug : "unset";
  const tags = props.image.tags ? props.image.tags.join(", ") : "Image has no tags";
  const size = props.image.size ? props.image.size : "unset";
  const imageType = props.image.image_type ? props.image.image_type : "unset";
  const itemType = props.image.item_type ? props.image.item_type : "unset";
  const imageState  = props.image.image_state ? props.image.image_state : "unset";
  const sha256 = props.image.sha256 ? props.image.sha256 : "unset";

  //----------------------------------------------------------------

  return(
    <div className="mb-4">
      <Row>
        <Col className="h4 text-truncate mb-2">
          {name}
        </Col>
      </Row>
      <Row className="position-relative mb-2">
        <Col
          className="position-absolute h-100"
          hidden={!loading}
          style={{zIndex: 2}}
        >
          <BasicSpinner
            className="position-absolute top-50 start-50 translate-middle"
            text="Image loading..."
          />
        </Col>
        <Col
          className="position-absolute h-100 bg-light opacity-75"
          hidden={!loading}
          style={{zIndex: 1}}
        />
        <Col>
          <Image src={slug}
            fluid
            onLoad={() => setLoading(false)}
            style={{zIndex: 0}}
          />
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          ID: {props.image._id}
        </Col>
      </Row>*/}
      {/*<Row>
        <Col className="text-truncate">
          Uploaded Filename: {filename}
        </Col>
      </Row>*/}
      {/*<Row>
        <Col className="text-truncate">
          SHA 256 Hash: {sha256}
        </Col>
      </Row>*/}
      {/*<Row>
        <Col className="text-truncate">
          Size (bytes): {size}
        </Col>
      </Row>*/}
      <Row>
        <Col>
          Description: {description}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Type: {imageType}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Item Type: {itemType}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Image State: {imageState}
        </Col>
      </Row>
      <Row>
        <Col>
          Tags: {tags}
        </Col>
      </Row>
      <Row>
        <Col className="text-truncate">
          Added On: {createOn}
        </Col>
      </Row>
      {/*<Row>
        <Col className="text-truncate">
          Last Updated: {modifiedOn}
        </Col>
      </Row>*/}
    </div>
  );
}