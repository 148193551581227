import { Row, Col, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { ImageStates, ItemTypes } from '../../enums';
import { formatTagsMultipart } from '../../utils/utils';

import BasicSpinner from '../common/basic-spinner';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ImageCreateForm({onCreate, onCancel}) {
  const namePlaceholder = `New Image: ${new Date().toLocaleString()}`;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTagsString] = useState("");
  const [working, setWorking] = useState(false);
  const [imageState, setImageState] = useState(ImageStates.BEFORE);
  const [itemType, setItemType] = useState(ItemTypes.MAIN);
  const [file, setFile] = useState();
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const createImageHandler = useCallback(async () => {
    setWorking(true);

    const data = {
      name: name ? name : namePlaceholder,
      description: description,
      image_state: imageState,
      item_type: itemType
    };

    const searchParamsString = new URLSearchParams(data).toString();

    const formData = new FormData();

    if(tags){
      formData.append('tags', formatTagsMultipart(tags));
    }

    if(file){
      formData.append('image', file);
    } else {
      setToasts([...toasts, createToast(
        "Required:",
        "Please select an image to upload."
      )]);
      setWorking(false);
      return;
    }

    await fetch(`/api/image/?${searchParamsString}`, {
      method : 'POST',
      body : formData
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onCreate(result);
      } else {
        setToasts([...toasts, createToast(
          "Failed to create new image",
          `${response.status} - ${JSON.stringify(result), null, "\t"}`
        )]);
      }
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to create new image: ${error.message}`
      )]);
    });

    setWorking(false);
  }, [name, description, imageState, itemType, tags, file, onCreate]);

  const handleFileSelection = useCallback(async (event) => {
    const selection = event.target.files[0];
    if(selection.type === "image/png" || selection.type === "image/jpeg"){
      setName(selection.name);
      setFile(selection);
    } else {
      setName("");
      setFile(undefined);
      setToasts([...toasts, createToast(
        "File type not accepted:",
        "Please select a .png or .jpg"
      )]);
    }
  });

  //----------------------------------------------------------------

  return (
    <Form className="mb-4">

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Image</Form.Label>
        <Form.Control
          type="file"
          onChange={handleFileSelection}
        />
        <Form.Text className="text-muted">
          PNG, JPEG or JPG only, please.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          placeholder={namePlaceholder}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Image a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter descrition"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of the image.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item Type</Form.Label>
        <Form.Select
          onChange={(event) => setItemType(event.target.value)}
          disabled={working}
          defaultValue={itemType}
        >
          {Object.keys(ItemTypes).map(key =>
            <option
              key={`itemType-option-${key}`}
              value={ItemTypes[key]}
            >
              {ItemTypes[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>State</Form.Label>
        <Form.Select
          onChange={(event) => setImageState(event.target.value)}
          disabled={working}
          defaultValue={imageState}
        >
          {Object.keys(ImageStates).map(key =>
            <option
              key={`imageState-option-${key}`}
              value={ImageStates[key]}
            >
              {ImageStates[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Tags</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter tags"
          onChange={(event) => setTagsString(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Single-word tags separated by spaces, please.
        </Form.Text>
      </Form.Group>

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={createImageHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Uploading Image..." />
              :
              "Upload New Image"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
