import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { PlasticCodes, ItemStates } from '../../enums';

import BasicSpinner from '../common/basic-spinner';
import UpdateFormTags from '../common/update-form-tags';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ItemUpdateForm({item, onUpdate, onCancel}){

  const [name, setName] = useState(item.name);
  const [itemDescription, setItemDescription] = useState(item.item_description);
  const [envDescription, setEnvDescription] = useState(item.env_description);
  const [plasticCode, setPlasticCode] = useState(item.plastic_code);
  const [itemState, setItemState] = useState(item.item_state);
  // const [tags, setTagsString] = useState(item.tags ? item.tags.join(", ") : "");
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  //----------------------------------------------------------------

  const sendUpdateRequest = useCallback(async (data, itemId) => {
    if (Object.keys(data).length <= 0) return null;

    setWorking(true);
    const item = await fetch(`/api/item/${itemId}`, {
      method : 'PUT',
      body : JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if(response.ok) {
        onUpdate(result);
        return result
      }
      setToasts([...toasts, createToast(
        "Failed to update item",
        `${response.status} - ${response.json()}`
      )]);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `Failed to update item ${itemId}: ${error.message}`
      )]);
    });
    setWorking(false);
    return item;
  }, [onUpdate]);

  const submitHandler = useCallback(async () => {
    const data = {};

    if (name !== item.name){
      data['name']=name;
    }

    if (itemDescription !== item.item_description){
      data['item_description'] = itemDescription;
    }

    if (envDescription !== item.env_description){
      data['env_description'] = envDescription;
    }

    if(plasticCode !== item.plastic_code){
     data['plastic_code'] = plasticCode;
    }

    if(itemState !== item.item_state){
     data['item_state'] = itemState;
    }

    await sendUpdateRequest(data, item._id);
    onCancel();
  }, [name, itemDescription, envDescription, plasticCode, itemState, item, onCancel]);

  //----------------------------------------------------------------

  return(
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Item a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item Description</Form.Label>
        <Form.Control
          as="textarea"
          value={itemDescription}
          placeholder="Enter description"
          onChange={(event) => setItemDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of the item.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Environment Description</Form.Label>
        <Form.Control
          as="textarea"
          value={envDescription}
          placeholder="Enter description"
          onChange={(event) => setEnvDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of where the item was found.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Plastics Code</Form.Label>
        <Form.Select
          onChange={(event) => setPlasticCode(parseInt(event.target.value))}
          disabled={working}
          defaultValue={plasticCode}
        >
          {Object.keys(PlasticCodes).map(key =>
            <option
              key={`plasticCode-option-${key}`}
              value={PlasticCodes[key]['value']}
            >
              {PlasticCodes[key]['name']}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Item State</Form.Label>
        <Form.Select
          onChange={(event) => setItemState(event.target.value)}
          disabled={working}
          defaultValue={itemState}
        >
          {Object.keys(ItemStates).map(key =>
            <option
              key={`itemState-option-${key}`}
              value={ItemStates[key]}
            >
              {ItemStates[key]}
            </option>
          )}
        </Form.Select>
      </Form.Group>

      <UpdateFormTags
        object={item}
        onSubmit={sendUpdateRequest}
        disabled={working}
      />

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={submitHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Saving Changes..." />
              :
              "Save Changes"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
