import { Col, Row, Form, Button } from 'react-bootstrap';
import { useState, useCallback } from 'react';

import BasicSpinner from '../common/basic-spinner';
import UpdateFormTags from '../common/update-form-tags';
import { Toaster, createToast } from '../common/toasts';

//================================================================

export default function ForayUpdateForm({foray, onUpdate, onCancel}){

  const [title, setTitle] = useState(foray.title);
  const [description, setDescription] = useState(foray.description);
  const [working, setWorking] = useState(false);
  const [toasts, setToasts] = useState([]);

  const sendUpdateRequest = useCallback(async (data, forayId) => {
    setWorking(true);
    if (Object.keys(data).length <= 0) return null;

    const updatedForay = await fetch(`/api/foray/${forayId}`, {
      method : 'PUT',
      body : JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        onUpdate(result);
        return result;
      }
      setToasts([...toasts, createToast(
        `Failed to update foray`,
        `${response.status} - ${response.json()}`
      )]);
    })
    .catch((error) => {
      setToasts([...toasts, createToast(
        "Error",
        `While updating foray ${forayId}: ${error.message}`
      )]);
    });
    setWorking(false);
    return updatedForay;
  }, [onUpdate]);

  const submitHandler = useCallback(async () => {
    const data = {};

    if (title !== foray.title){
      data['title'] = title;
    }

    if (description !== foray.description){
      data['description'] = description;
    }

    await sendUpdateRequest(data, foray._id);
    onCancel();
  }, [title, description, foray, onCancel]);

  //----------------------------------------------------------------

  return(
    <Form className="mb-4">

      <Form.Group className="mb-4">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
          Give the Foray a Name.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          value={description}
          placeholder="Enter descrition"
          onChange={(event) => setDescription(event.target.value)}
          disabled={working}
        />
        <Form.Text className="text-muted">
            A short description of where you are and where you'll be going.
        </Form.Text>
      </Form.Group>

      <UpdateFormTags
        object={foray}
        onSubmit={sendUpdateRequest}
        disabled={working}
      />

      <Row className="align-items-center">
        <Col className="text-start">
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={working}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-end">
          <Button
            className="text-nowrap"
            variant="primary"
            onClick={submitHandler}
            disabled={working}
          >
            {working ?
              <BasicSpinner text="Saving Changes..." />
              :
              "Save Changes"
            }
          </Button>
        </Col>
      </Row>

      <Toaster toasts={toasts} />

    </Form>
  );
}
